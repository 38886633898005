import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  useDisclosure,
  Button,
  ListItem,
  HStack,
  Text,
} from '@chakra-ui/react'
import Link from 'next/link'
import React, { FC } from 'react'
import routes from 'src/util/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHouse,
  faListCheck,
  faSitemap,
  faUserGroup,
  faAngleRight,
  faCashRegister,
  faBullhorn,
  faBars,
  faGear,
  faCircleQuestion,
} from '@fortawesome/free-solid-svg-icons'
import styles from './style.module.scss'
import { useRouter } from 'next/router'

const DrawerMenu: FC = () => {
  const router = useRouter()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = React.useRef<HTMLButtonElement>(null)

  const menuList = [
    { name: 'ホーム', href: routes.home, icon: faHouse },
    { name: '予約', href: routes.reservations.index, icon: faListCheck },
    { name: 'プラン', href: routes.plans.index, icon: faSitemap },
    { name: '顧客', href: routes.customers.index, icon: faUserGroup },
    { name: '売上', href: routes.monthlyIncomes.index, icon: faCashRegister },
    { name: 'マーケティング', href: routes.marketing.index, icon: faBullhorn },
    { name: '設定', href: routes.setting.index, icon: faGear },
    { name: 'ヘルプ', href: `https://help.jammy.jp`, icon: faCircleQuestion },
  ]

  return (
    <>
      <Button
        ref={btnRef}
        onClick={onOpen}
        size="lg"
        className={styles.openButton}
        borderRadius="full"
        fontSize="16px"
        h="44px"
        w="44px"
        bgColor="primary"
        zIndex={10}
      >
        <FontAwesomeIcon
          icon={faBars}
          className={styles.openButtonIcon}
          color="#fff"
        />
      </Button>
      <Drawer
        isOpen={isOpen}
        onClose={onClose}
        placement="left"
        finalFocusRef={btnRef}
      >
        <DrawerOverlay>
          <DrawerContent bg="#272934" minW="70vw">
            <DrawerBody p="0">
              {menuList.map((menu, idx) => (
                <Link href={menu.href} key={idx}>
                  <a>
                    <ListItem
                      data-active={router.pathname === menu.href}
                      className={styles.listItem}
                    >
                      <HStack>
                        <FontAwesomeIcon
                          icon={menu.icon}
                          className={styles.listIcon}
                        />
                        <Text className={styles.listText}>{menu.name}</Text>
                      </HStack>
                      <FontAwesomeIcon
                        icon={faAngleRight}
                        className={styles.listIconArrow}
                      />
                    </ListItem>
                  </a>
                </Link>
              ))}
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
    </>
  )
}

export default DrawerMenu
