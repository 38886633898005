import { Box, Container, HStack, useToast } from '@chakra-ui/react'
import { CurrentOperatorDetailFragment } from 'generated/graphql'
import { useRouter } from 'next/router'
import React, { FC, useEffect } from 'react'
import Header from 'src/components/Header'
import Sidebar from 'src/components/Sidebar'
import { ToastError } from 'src/components/Toast'
import { LocalStorageKeys } from 'src/util/persistenceKeys'
import routes from 'src/util/routes'
import styles from './style.module.scss'

type Props = {
  currentOperator?: CurrentOperatorDetailFragment | null
}

export const LoginContainer: FC<Props> = ({ children, currentOperator }) => {
  const router = useRouter()
  const toast = useToast()

  useEffect(() => {
    if (!currentOperator) {
      // operatorとcompany紐付けないといけないのでcompanyIdをどこから取得するか問題
      // 一応localStorageから持ってくる形式にしたが、改善の余地あり

      const companyId = localStorage.getItem(LocalStorageKeys.myCompanyId)
      if (!companyId) {
        toast({
          status: 'error',
          isClosable: true,
          position: 'top',
          render: () => (
            <ToastError>
              スタッフ登録が正しく行われませんでした。登録URLを確認してください。
            </ToastError>
          ),
        })
        location.href = routes.lp
        return
      }
      router.push({
        pathname: routes.operators.new,
        query: {
          companyId,
        },
      })
    }

    if (
      !localStorage.getItem(LocalStorageKeys.currentManageHotelId) &&
      currentOperator
    ) {
      if (!currentOperator?.hotelList?.items)
        throw new Error('ホテル情報を取得できませんでした。')

      localStorage.setItem(
        LocalStorageKeys.currentManageHotelId,
        currentOperator?.hotelList?.items[0].id
      )
    }
  }, [currentOperator, router])

  return currentOperator ? (
    <>
      <Header currentOperator={currentOperator} />
      <HStack p={{ md: '56px 0', base: '56px 0' }}>
        <Box w={{ md: '212px', base: '0' }}>
          <Sidebar />
        </Box>

        <Box minH="100vh" className={styles.main} marginInlineStart="0">
          <Container maxW="none" p="0">
            {children}
          </Container>
          <footer className={styles.footer}>
            <a href={routes.terms} target="_blank" rel="noopener noreferrer">
              利用規約
            </a>
            <a href={routes.privacy} target="_blank" rel="noopener noreferrer">
              プライバシーポリシー
            </a>
            <a
              href={routes.lawTokutei}
              target="_blank"
              rel="noopener noreferrer"
            >
              特定商取引法に基づく表記
            </a>
          </footer>
        </Box>
      </HStack>
    </>
  ) : (
    <Container maxW="1180px" p="40px 24px">
      {children}
    </Container>
  )
}
