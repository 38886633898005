import { useGetCurrentOperatorQuery } from 'generated/graphql'
import React, { FC } from 'react'
import Loading from '../../Loading'
import { LoginContainer } from './LoginContainer'

export const LoginLayout: FC = ({ children }) => {
  const { data, loading } = useGetCurrentOperatorQuery()

  if (loading) return <Loading />

  return (
    <LoginContainer currentOperator={data?.currentOperator}>
      {children}
    </LoginContainer>
  )
}
