import { Text, TextProps } from '@chakra-ui/react'
import { FC } from 'react'

export const SmallHeading: FC<TextProps> = (props) => (
  <Text {...props} fontSize={{ md: '16px', base: '14px' }} fontWeight="bold">
    {props.children}
  </Text>
)

export const MediumHeading: FC<TextProps> = (props) => (
  <Text {...props} fontSize={{ md: '20px', base: '16px' }} fontWeight="bold">
    {props.children}
  </Text>
)

export const LargeHeading: FC<TextProps> = (props) => (
  <Text {...props} fontSize={{ md: '24px', base: '18px' }} fontWeight="bold">
    {props.children}
  </Text>
)

export const XLargeHeading: FC<TextProps> = (props) => (
  <Text {...props} fontSize={{ md: '30px', base: '24px' }} fontWeight="bold">
    {props.children}
  </Text>
)
