import { Select, SelectProps } from '@chakra-ui/react'
import { useGetCurrentOperatorHotelListQuery } from 'generated/graphql'
import React, { FC } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faAngleDown } from '@fortawesome/free-solid-svg-icons'

type Props = SelectProps

// FIXME: React.forwardRef使わないとWarning出る現象の解決法がわかったら教えてください
const ManageHotelSelect: FC<Props> = React.forwardRef(
  function ManageHotelSelect(props, ref) {
    const { data } = useGetCurrentOperatorHotelListQuery()

    return (
      <Select
        {...props}
        placeholder={props.defaultValue ? undefined : 'ホテルを選択'}
        variant="unstyled"
        iconSize="12"
        fontWeight="bold"
        p="6px 8px 6px 0"
        icon={<FontAwesomeIcon icon={faAngleDown} />}
      >
        {data?.currentOperator?.hotelList?.items?.map((hotel) => (
          <option key={hotel.id} value={hotel.id} label={hotel.name}>
            {hotel.name}
          </option>
        ))}
      </Select>
    )
  }
)

export default ManageHotelSelect
