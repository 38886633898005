import { Center } from '@chakra-ui/layout'
import { Spinner } from '@chakra-ui/react'
import React from 'react'

const Loading = () => (
  <Center height="100vh">
    <Spinner
      thickness="3px"
      speed="0.8s"
      emptyColor="#ffffff"
      color="#FF0B5E"
      size="xl"
    />
  </Center>
)

export default Loading
