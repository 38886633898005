import { Text, TextProps, HStack, Box } from '@chakra-ui/react'
import { FC } from 'react'
import styles from './style.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons'

type Props = TextProps

export const ToastSuccess: FC<Props> = (props) => {
  return (
    <Box className={`${styles.toast}` + ` ` + `${styles.success}`}>
      <HStack>
        <FontAwesomeIcon icon={faInfoCircle} className={styles.icon} />
        <Text className={styles.text}>{props.children}</Text>
      </HStack>
    </Box>
  )
}

export const ToastInfo: FC<Props> = (props) => {
  return (
    <Box className={`${styles.toast}` + ` ` + `${styles.info}`}>
      <HStack>
        <FontAwesomeIcon icon={faInfoCircle} className={styles.icon} />
        <Text className={styles.text}>{props.children}</Text>
      </HStack>
    </Box>
  )
}

export const ToastError: FC<Props> = (props) => {
  return (
    <Box className={`${styles.toast}` + ` ` + `${styles.error}`}>
      <HStack>
        <FontAwesomeIcon icon={faInfoCircle} className={styles.icon} />
        <Text className={styles.text}>{props.children}</Text>
      </HStack>
    </Box>
  )
}
