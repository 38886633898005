import { Box, Container, HStack, Image } from '@chakra-ui/react'
import React, { FC } from 'react'
import { SmallHeading } from 'src/components/Heading'
import routes from 'src/util/routes'
import styles from './style.module.scss'

export const GuestLayout: FC = ({ children }) => {
  return (
    <Container maxW="1180px" minH="100vh">
      <header className={styles.header}>
        <a>
          <HStack>
            <Image
              src="/jammy-logo-flat.svg"
              alt="Jammy Logo"
              width="36px"
              height="26px"
            />
            <SmallHeading>Jammy</SmallHeading>
          </HStack>
        </a>
      </header>
      <Box className={styles.content}>{children}</Box>
      <footer className={styles.footer}>
        <a href={routes.terms} target="_blank" rel="noopener noreferrer">
          利用規約
        </a>
        <a href={routes.privacy} target="_blank" rel="noopener noreferrer">
          プライバシーポリシー
        </a>
        <a href={routes.lawTokutei} target="_blank" rel="noopener noreferrer">
          特定商取引法に基づく表記
        </a>
      </footer>
    </Container>
  )
}
