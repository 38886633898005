import {
  HStack,
  Text,
  Box,
  Button,
  Image,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
} from '@chakra-ui/react'
import React, { FC } from 'react'
import { CurrentOperatorDetailFragment } from 'generated/graphql'
import routes from 'src/util/routes'
import { logout } from 'src/util/firebaseHelpers'
import ManageHotelSelect from '../Select/ManageHotelSelect'
import { LocalStorageKeys } from 'src/util/persistenceKeys'
import styles from './style.module.scss'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
import Link from 'next/link'

type Props = {
  currentOperator: CurrentOperatorDetailFragment
}

const Header: FC<Props> = ({ currentOperator }) => {
  const hotelId = localStorage.getItem(
    LocalStorageKeys.currentManageHotelId
  ) as string

  const onChangeCurrentManageHotel = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    try {
      localStorage.setItem(
        LocalStorageKeys.currentManageHotelId,
        e.target.value
      )

      location.href = routes.home
    } catch (e) {
      throw e
    }
  }

  return (
    <header className={styles.header}>
      <HStack>
        <Link href={routes.home}>
          <a>
            <Image
              src="/jammy-logo-flat.svg"
              alt="Jammy Logo"
              width="36px"
              height="26px"
              mr="3"
            />
          </a>
        </Link>
        <ManageHotelSelect
          defaultValue={hotelId}
          onChange={onChangeCurrentManageHotel}
        />
      </HStack>

      <HStack>
        <a
          href={`${process.env.NEXT_PUBLIC_APP_CUSTOMER_URL}/r/${hotelId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Button
            className={styles.button}
            leftIcon={<FontAwesomeIcon icon={faEye} />}
            mr="2"
          >
            予約ページ
          </Button>
        </a>

        <Popover>
          <PopoverTrigger>
            <Box
              bgGradient="linear(to-t, #ff7337, #ff0b5e)"
              w="36px"
              h="36px"
              borderRadius="50%"
              display="flex"
              justifyContent="center"
              alignItems="center"
              cursor="pointer"
            >
              <Text fontSize="16px" fontWeight="bold" color="white">
                {currentOperator.fullName.charAt(0)}
              </Text>
            </Box>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverCloseButton />
              <PopoverBody>
                <Button size="lg" onClick={logout}>
                  ログアウト
                </Button>
              </PopoverBody>
            </PopoverContent>
          </Portal>
        </Popover>
      </HStack>
    </header>
  )
}

export default Header
