import React, { FC } from 'react'
import { Stack, HStack, Box } from '@chakra-ui/react'
import Navigation from './Navigation'
import DrawerMenu from './Drawer'

const Sidebar: FC = () => {
  return (
    <Stack>
      <HStack p={5}>
        <Box display={{ base: 'block', md: 'none' }}>
          <DrawerMenu />
        </Box>
      </HStack>
      <HStack alignItems="start">
        <Box display={{ base: 'none', md: 'block' }} w={500} px={6}>
          <Navigation />
        </Box>
      </HStack>
    </Stack>
  )
}

export default Sidebar
