import { getAuth } from 'firebase/auth'
import { useRouter } from 'next/router'
import React, { FC } from 'react'
import { useAuthState } from 'react-firebase-hooks/auth'
import routes from 'src/util/routes'
import Loading from '../Loading'
import { GuestLayout } from './GuestLayout'
import { LoginLayout } from './LoginLayout'

// eslint-disable-next-line react/display-name
export const withGuestLayout = (Container: FC<unknown>) => () => {
  return (
    <GuestLayout>
      <Container />
    </GuestLayout>
  )
}

// eslint-disable-next-line react/display-name
export const withLoginLayout = (Container: FC<unknown>) => () => {
  const [user, authLoading] = useAuthState(getAuth())
  const router = useRouter()

  if (authLoading) return <Loading />

  if (!authLoading && !user) {
    location.href = routes.login
    return null
  }

  return (
    <LoginLayout>
      <Container />
    </LoginLayout>
  )
}
