import { ListItem, UnorderedList, Box, HStack, Text } from '@chakra-ui/react'
import Link from 'next/link'
import React, { FC } from 'react'
import routes from 'src/util/routes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faHouse,
  faListCheck,
  faSitemap,
  faUserGroup,
  faGear,
  faAngleRight,
  faCashRegister,
  faCircleQuestion,
  faBullhorn,
} from '@fortawesome/free-solid-svg-icons'
import styles from './style.module.scss'
import { useRouter } from 'next/router'

const Navigation: FC = () => {
  const router = useRouter()
  const menuList = [
    { name: 'ホーム', href: routes.home, icon: faHouse },
    { name: '予約', href: routes.reservations.index, icon: faListCheck },
    { name: 'プラン', href: routes.plans.index, icon: faSitemap },
    { name: '顧客', href: routes.customers.index, icon: faUserGroup },
    { name: '売上', href: routes.monthlyIncomes.index, icon: faCashRegister },
    { name: 'マーケティング', href: routes.marketing.index, icon: faBullhorn },
  ]

  return (
    <nav className={styles.Navigation}>
      <UnorderedList
        marginInline="0"
        styleType="none"
        position="relative"
        height="100%"
        className={styles.NavigationInner}
      >
        {menuList.map((menu, idx) => (
          <Link href={menu.href} key={idx}>
            <a>
              <ListItem
                data-active={router.pathname === menu.href}
                className={styles.listItem}
              >
                <HStack>
                  <FontAwesomeIcon
                    icon={menu.icon}
                    className={styles.listIcon}
                  />
                  <Text className={styles.listText}>{menu.name}</Text>
                </HStack>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={styles.listIconArrow}
                />
              </ListItem>
            </a>
          </Link>
        ))}
        <Box className={styles.listBottom}>
          <Link href={routes.setting.index}>
            <a>
              <ListItem
                className={styles.listItem}
                data-active={router.pathname === routes.setting.index}
              >
                <HStack>
                  <FontAwesomeIcon icon={faGear} className={styles.listIcon} />
                  <Text className={styles.listText}>設定</Text>
                </HStack>
                <FontAwesomeIcon
                  icon={faAngleRight}
                  className={styles.listIconArrow}
                />
              </ListItem>
            </a>
          </Link>
          <a href="https://help.jammy.jp" target="_blank" rel="noreferrer">
            <ListItem className={styles.listItem}>
              <HStack>
                <FontAwesomeIcon
                  icon={faCircleQuestion}
                  className={styles.listIcon}
                />
                <Text className={styles.listText}>ヘルプ</Text>
              </HStack>
              <FontAwesomeIcon
                icon={faAngleRight}
                className={styles.listIconArrow}
              />
            </ListItem>
          </a>
        </Box>
      </UnorderedList>
    </nav>
  )
}

export default Navigation
